<script setup lang="ts">
import GenericError from '~/components/user-interface/generic-error.vue';
import { uuid } from '~/utilities/uuid';
import { useSiteStore } from '~/stores/site.store';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';
import ProgressBar from 'primevue/progressbar';
import type {
  severity,
  position,
} from '~/interfaces/dto/general/toast-messages';

const site = useSiteStore();

const toast = useToast();

interface ToastProps {
  type: severity;
  heading: string;
  message: string;
  count?: number;
  position?: position;
  timeout?: number;
  enabled: boolean;
}
const props = withDefaults(defineProps<ToastProps>(), {
  type: 'success',
  heading: '',
  message: '',
  count: 1,
  position: 'br',
  timeout: 3000,
  enabled: false,
});

const computedPosition = computed(() => {
  switch (props.position) {
    case 'tl':
      return 'top-left';
    case 'bl':
      return 'bottom-left';
    case 'br':
      return 'bottom-right';
    case 'c':
      return 'center';
    case 'tr':
      return 'top-right';
    case 'bc':
      return 'bottom-center';
    case 'tc':
      return 'top-center';
  }
});
const progressBars = ref({});
const intervals = ref({});
const smallScreen = useMatchMedia('(max-width: 479px)');

function createInterval(id: string) {
  progressBars.value[id] = 100;
  intervals.value[id] = setInterval(() => {
    progressBars.value[id] = progressBars.value[id] - 1;
  }, props.timeout / 100);
}
function clearProgressInterval(id: string) {
  clearInterval(intervals.value[id]);
  site.toggleSiteNotice({
    heading: '',
    message: '',
    severity: 'danger',
    enabled: false,
    timeout: 3000,
  });
  delete progressBars.value[id];
}

function closeToaster(id: string) {
  toast.remove(id);
}

watch(
  () => props,
  () => {
    const id = uuid();
    if (props.message || props.heading) {
      toast.add({
        severity: props.type,
        summary: props.heading,
        detail: props.message,
        group: props.position,
        life: props.timeout,
        closable: true,
        id,
      });
      createInterval(id);
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
<template>
  <Toast
    :group="props.position"
    :position="computedPosition"
    unstyled
    @life-end="(e) => clearProgressInterval(e.message.id)"
    close-icon="hidden"
  >
    <template #message="slotProps">
      <GenericError
        class="bg-white dark:bg-dark-800 rounded-md"
        :state="props.type"
      >
        <div :class="smallScreen ? 'text-center' : ''">
          <div class="font-bold">{{ slotProps.message.summary }}</div>
          <div>
            {{ slotProps.message.detail }}
          </div>
          <ProgressBar
            style="height: 6px"
            :class="['mt-2', props.type]"
            :value="progressBars[slotProps.message.id]"
            :show-value="false"
          ></ProgressBar>
          <div class="w-full">
            <DesignSystemButton
              class="p-button-sm"
              label="Close"
              @click="(e) => closeToaster(slotProps.message.id)"
            >
              {{ $t('close') }}
            </DesignSystemButton>
          </div>
        </div>
      </GenericError>
    </template>
  </Toast>
</template>
<style lang="scss" scoped>
.p-progressbar {
  &.error {
    :deep(.p-progressbar-value) {
      background-image: linear-gradient(
        45deg,
        #9c4141 0%,
        #dc2626 100%
      ) !important;
    }
  }
  &.success {
    :deep(.p-progressbar-value) {
      background-image: linear-gradient(
        45deg,
        #688c32 0%,
        #84cc16 100%
      ) !important;
    }
  }
  &.info {
    :deep(.p-progressbar-value) {
      background-image: linear-gradient(
        45deg,
        #2530ac 0%,
        #3879fb 100%
      ) !important;
    }
  }
  &.warn {
    :deep(.p-progressbar-value) {
      background-image: linear-gradient(
        45deg,
        #d87700 0%,
        #ffc018 100%
      ) !important;
    }
  }
}
</style>
